import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Stack() {
  return (
   <>
    <h1>
    Coding is fun!
    </h1>
    </>
  );
}
